export const tbclOffers: Offer[] = [
  {
    imageUrl: 'bmw.jpg',
    title: 'შეთავაზება BMW-ზე',
    subheader: 'GT Motors',
    documentUrl: 'bmw.pdf'
  },
  {
    imageUrl: 'mini.jpg',
    title: 'შეთავაზება Mini-ზე',
    subheader: 'GT Motors',
    documentUrl: 'mini.pdf'
  },
  {
    imageUrl: 'land_rover_2.jpg',
    title: 'შეთავაზება Land Rover-ზე',
    subheader: 'GT Motors',
    documentUrl: 'land_rover_2.pdf'
  },
  {
    imageUrl: 'land_rover.jpg',
    title: 'შეთავაზება Land Rover-ზე',
    subheader: 'GT Motors',
    documentUrl: 'land_rover.pdf'
  },
  {
    imageUrl: 'lexsus.jpg',
    title: 'საახალწლო შეთავაზება',
    subheader: 'Lexus Tbilisi',
    documentUrl: 'lexsus.pdf'
  },
  {
    imageUrl: 'volvo.jpg',
    title: 'საახალწლო შეთავაზება',
    subheader: 'Volvo Car Sakartvelo',
    documentUrl: 'volvo.pdf'
  },
  {
    imageUrl: 'gt_motors.jpg',
    title: '6 თვეში დაიბრუნე საგარანტიო თანხა 20%',
    subheader: 'GT Motors',
    documentUrl: 'gt_motors.pdf'
  },
  {
    imageUrl: 'mercedes_benz.jpg',
    title: 'სპეციალური ფასი და 6 თვიანი საშეღავათო პერიოდი',
    subheader: 'Mercedes-Benz Georgia',
    documentUrl: 'mercedes_benz.pdf'
  },
  {
    imageUrl: 'gt_group.jpg',
    title: 'შეიძინე თვითმცლელი 6 თვიანი 0%-იანი ლიზინგით',
    subheader: 'GT Group',
    documentUrl: 'gt_group.pdf'
  },
  {
    imageUrl: 'renault.jpg',
    title: 'სპეციალური შეთავაზება',
    subheader: 'Renault Georgia',
    documentUrl: 'renault.pdf'
  },
  {
    imageUrl: 'mazda2.jpg',
    title: '1 წლიანი 0%-იანი ლიზინგი',
    subheader: 'Mazda Center Tegeta',
    documentUrl: 'mazda2.pdf'
  },
  {
    imageUrl: 'zeekr.jpg',
    title: 'სპეციალური შეთავაზება',
    subheader: 'Zeekr Sakartvelo',
    documentUrl: 'zeekr.pdf'
  },
  {
    imageUrl: 'peugeot.jpg',
    title: 'სპეციალური ფასდაკლება საშუალო ზომის სატვირთო ავტომობილზე',
    subheader: 'Peugeot Georgia',
    documentUrl: 'peugeot.pdf'
  },
  {
    imageUrl: 'hyundai2.jpg',
    title: 'HYUNDAI-ს ექსკავატორი 1 წლიანი 0%-იანი ლიზინგით',
    subheader: 'Caucasus Machinery',
    documentUrl: 'hyundai2.pdf'
  },
  {
    imageUrl: 'scooters.jpg',
    title: 'შეიძინე ელექტრო სკუტერები ფასდაკლებით + საჩუქარი',
    subheader: 'Autogallery',
    documentUrl: 'scooters.pdf'
  },
  {
    imageUrl: 'changan.jpg',
    title: 'შეიძინე Changan Eado EV 460 ლიზინგით სპეციალურ ფასად',
    subheader: 'Changan Georgia',
    documentUrl: 'changan.pdf'
  },
  {
    imageUrl: 'byd_seagull.jpg',
    title: 'შეიძინე BYD Seagull 44,795 ლარად',
    subheader: 'Solar Auto',
    documentUrl: 'byd_seagull.pdf'
  }
];

export interface Offer {
  imageUrl: string;
  title: string;
  subheader: string;
  documentUrl: string;
}
