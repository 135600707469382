import { Injectable } from '@angular/core';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor() {}

  eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    try {
      gtag('event', eventName, {
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      });
    } catch (error) {
      console.log(error);
    }
  }
}
